<template>
  <div class="absolute top-0">
    <div class="fixed flex xl:gap-200 lg:gap-150 md:gap-100 h-screen w-full bg-white">
      <div class="purple-card relative">
        <img
          src="/content/images/brightbid/brightbid-logo-white.svg"
          alt="Brightbid"
        />
        <div class="get-started font-bold">
          <div class="w-full h-full flex items-center justify-center">
            <div>
              <h1 class="gradient text-5xl mb-0 leading-normal">Get started</h1>
              <h1 class="mb-0 text-5xl leading-normal text-white">{{ subTitle }}</h1>
            </div>
          </div>
        </div>

        <img
          src="/content/images/illustrations/onboarding-rectangles-1.svg"
          class="onboarding-rectangles"
          alt="Brightbid"
        />
        <img
          src="/content/images/illustrations/onboarding-skewed-circles-1.svg"
          class="skewed-circles"
          alt="Brightbid"
        />
      </div>

      <!--Signup form-->
      <div class="h-screen flex items-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingComponent',
  props: {
    subTitle: {
      type: String,
      default: 'with Brightbid',
    },
  },
}
</script>

<style scoped lang="scss">
.gradient {
  background: linear-gradient(167deg, #84fae4 45.93%, #677fea 80.03%, #5369cd 112.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.purple-card {
  width: 635px;
  padding: 100px;
  background: $bb-brand-purple;
}

.get-started {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: 100px;
  padding-bottom: 50px;
}

.skewed-circles {
  position: absolute;
  bottom: 0;
  right: 25px;
}

.onboarding-rectangles {
  position: absolute;
  bottom: 0;
  left: 170px;
}
</style>
